// Store
import store from "@/store/index";

export interface AppOrganizationCreateIndex {
  user_id: string | string[];
  content: {
    name: string;
    address: string;
    place: string;
    country: string;
    identificationNumber: string;
    vatNumber: string;
    phone: string;
  };
}

export class AppOrganizationCreateIndex implements AppOrganizationCreateIndex {
  user_id: string | string[];
  content: {
    name: string;
    address: string;
    place: string;
    country: string;
    identificationNumber: string;
    vatNumber: string;
    phone: string;
  };

  constructor() {
    this.user_id = store.getters.getAuthentication.id ?? "";
    this.content = {
      name: "",
      address: "",
      place: "",
      country: "",
      identificationNumber: "",
      vatNumber: "",
      phone: "",
    };
  }
}