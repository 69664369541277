import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a5a822a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-organization-wizard" }
const _hoisted_2 = { class: "section-default" }
const _hoisted_3 = { class: "app-organization-wizard__headline" }
const _hoisted_4 = { class: "app-organization-wizard__headline-title" }
const _hoisted_5 = { class: "app-organization-wizard__form" }
const _hoisted_6 = { class: "form--default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 16,
            type: "flex",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 12,
                md: 10,
                lg: 10,
                xl: 10
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.createOrganization), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_el_form, {
                        model: _ctx.formData,
                        rules: _ctx.formDataRules,
                        ref: "formComponent",
                        onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: _ctx.translation.title,
                            prop: "content.name"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: _ctx.formData.content.name,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.content.name) = $event)),
                                placeholder: _ctx.translation.titlePlaceholderOrganization
                              }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, {
                            label: _ctx.translation.address,
                            prop: "content.address"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: _ctx.formData.content.address,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.content.address) = $event)),
                                placeholder: _ctx.translation.addressPlaceholder
                              }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, {
                            label: _ctx.translation.place,
                            prop: "content.place"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: _ctx.formData.content.place,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.content.place) = $event)),
                                placeholder: _ctx.translation.placePlaceholder
                              }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, {
                            label: _ctx.translation.country,
                            prop: "content.country"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: _ctx.formData.content.country,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.content.country) = $event)),
                                placeholder: _ctx.translation.countryPlaceholder
                              }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, {
                            label: _ctx.translation.identificationNumber,
                            prop: "content.identificationNumber"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: _ctx.formData.content.identificationNumber,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.content.identificationNumber) = $event)),
                                placeholder: _ctx.translation.identificationNumberPlaceholder
                              }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, {
                            label: _ctx.translation.vatNumber,
                            prop: "content.vatNumber"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: _ctx.formData.content.vatNumber,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.content.vatNumber) = $event)),
                                placeholder: _ctx.translation.vatNumberPlaceholder
                              }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, {
                            label: _ctx.translation.phone,
                            prop: "content.phone"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: _ctx.formData.content.phone,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.content.phone) = $event)),
                                placeholder: _ctx.translation.phonePlaceholder
                              }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                type: "primary",
                                class: "el-button--primary el-button--block",
                                "native-type": "submit",
                                loading: _ctx.isLoading
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translation.continue), 1)
                                ]),
                                _: 1
                              }, 8, ["loading"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model", "rules", "onSubmit"])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}