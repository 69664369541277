
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppOrganizationCreateIndex} from "@/models/app/organization/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElContainer,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
} from "element-plus";

// Services
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElContainer,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
})
export default class AppOrganizationWizardIndexVue extends Vue {
  isLoading = false;

  formData: AppOrganizationCreateIndex = new AppOrganizationCreateIndex();
  formDataRules = {
    content: {
      name: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      place: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      country: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      identificationNumber: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      vatNumber: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  get translation(): any {
    return getTranslation([
      "address",
      "addressPlaceholder",
      "country",
      "countryPlaceholder",
      "continue",
      "createOrganization",
      "identificationNumber",
      "identificationNumberPlaceholder",
      "phone",
      "phonePlaceholder",
      "place",
      "placePlaceholder",
      "title",
      "titlePlaceholderOrganization",
      "vatNumber",
      "vatNumberPlaceholder",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/administration/organization/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        store.dispatch("setOrganizationMember", r.data.organizationMember);
        store.dispatch("setOrganizationMembership", r.data.organizationMembership);

        this.$router.push({
          name: "AppAdministratorDashboardRootIndexVue"
        });
      }
    });
    this.isLoading = false;
  }
}
